@import "theme.scss";
@import "assets/icons/style.css";
@import "assets/icons/sprite/sprite.css";

html {
  @media print {
    app-header,
    app-content-menu,
    .search-container,
    app-footer,
    .floating-button {
      display: none !important;
    }
  }
}
.dialogIE {
  mat-dialog-container {
    border-radius: 0 !important;
    padding: 48px !important;
  }
}

.ui-dialog .mat-dialog-container{
  overflow:hidden !important;
}

html,
body {
  height: 100%;
}
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.head {
  .material-icons {
    cursor: pointer;
  }
}

.user-entry {
  .overview-container {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
