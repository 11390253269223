@mixin header-theme($theme) {
  app-header {
    .main-header {
      height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .logo {
        max-height: 80%;
        cursor: pointer;
      }
      .links {
        display: flex;
        gap: 12px;
        .link {
          background-color: #E1373D;
          color: #fff;
          font-weight: bold;
          padding: 8px 24px 6px;
          border-radius: 18px;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:last-child {
            border: 1px solid #E1373D;
            background-color: #fff;
            color: #E1373D;
          }
          i.active {
            transition: transform 0.2s;
            display: inline-flex;
            &.active {
            }
          }
          &.user {
            color: white;
            &:hover {
              color: white;
              box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.22);
            }
          }
          &.ui-button-round {
            color: white;
            padding: 10px 12px;
            border-radius: 20px;
            span + i {
              margin-right: -5px;
            }
          }

          ui-menu {
            z-index: 1;
            top: 100%;
            right: 0;
            position: absolute;
          }
          &.activeItem {
          }
            &:hover {
              cursor: pointer;
              filter: brightness(1.1);
            }
          i {
            display: none;
          }
        }
      }
      }
      .burger {
        display: none;
        font-size: 22px;
      }
      .mobile-menu {
        z-index: 5;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: map-get($theme, "primary");
        overflow: auto;

        .link {
          justify-content: space-between;
          display: flex;
          height: 50px;
          align-items: center;
          @extend .project-content-margin;
          font-size: 16px;
          border-bottom: 1px solid map-get(map-get($theme, "element"), "base");

          &:last-child {
            border-bottom: none;
          }

          i {
            display: inline;
          }
        }
      }


    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .white-bar {
        display: none;
      }
      .main-header {
        .links {
          display: none;
        }

        .burger {
          display: flex;
          height: 100%;
          margin-right: -12px;
          touch-action: none;
          width: 50px;
          justify-content: center;
          align-self: center;

          i {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.active {
            background: map-get($theme, "primary");
            color: white;
          }
        }
      }
    }
  }

}
