@mixin pagesOverride($theme) {
  [class*="page"] {
    margin-bottom: 60px;
    &:first-child {
      margin-top: 0;
      &.page-mediagallery{
        // h2{
        //   font-size: 45px;
        //   line-height: 50px;
        // }
      }
    }
    &:last-child{
      margin-bottom: 80px;
    }
    &.page-teaserstack {
      margin-top: 80px;
      margin-bottom: 60px;
      .columns-3 {
        h2{
          font-size: 25px;
          margin-top: 10px;
        }
        .image-container {
          //background-size: cover;
          height: 250px;
        }
        button {
          align-self: flex-start;
        }
      }
      .columns-2{
        display: flex;
        justify-content: center;
        @media  (min-width: map-get(map-get($theme, "breakpoints"), "phone")){

          height: 345px;
        }
      }
    }
    &.page-mediagallery {
      .caption {


        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media  (min-width: map-get(map-get($theme, "breakpoints"), "phone")){
            height: 310px !important;
        }
      }
    }
    &.page-stackAccordion {
      page-article {
        .project-content-margin {
          padding: 0;
          margin: 0;
        }
        .image {
          img {
            max-width: 100%;
          }
        }
      }
    }
    &.page-text{
      + .page-teaserstack .columns-3{
        // margin-top: -70px;
      }
    }
  }

  page-teaser-stack {
    h4 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .teaser-stack {
      &.project-content-margin {
        .teaser-item {
          margin-bottom: 20px;
          @media (min-width: 800px) {
            &.columns-1 {
              width: calc(100% - 10px);
            }
            &.columns-2 {
              width: calc(50% - 10px);
            }
            &.columns-3 {
              width: calc(33.33333% - 10px);
            }
            &.columns-4 {
              width: calc(25% - 10px);
            }
          }
        }
      }
      .teaser-item {
        @media  (min-width: map-get(map-get($theme, "breakpoints"), "phone")){

        min-height: 320px;
        }
      }
    }
  }
  //ui-menu{
  //  color:  map-get(map-get($theme, "text"), "60")
  //}
}
