@mixin header-footer-theme($theme) {

  app-header-and-footer {
    display: flex;
    height: 100vh;
    flex-direction: column;
    >.content {
      background: #f8f8f8;
      flex: 1;
      @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      }
    }

    .topbar {
      margin-bottom: 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      ui-breadcrumb {
        flex: 3;
      }

      .search-container {
        flex: 0 0 25%;
      }

    }

    .floating-button {
      position: fixed;
      bottom: 40px;
      right: 20px;
      background-color: map-get($map: $theme, $key: primary);
      border-radius: 100%;
      border: none;
      font-size: 16px;
      font-family: inherit;
      color: #FFFFFF;
      display: none;
      cursor: pointer;
      height: 48px;
      width: 48px;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.5);

      &:focus {
        outline: none;
      }
    }

    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
      .topbar {
        display: block;

        ui-breadcrumb {
          flex: 1;
          margin-bottom: 10px;
        }

        .search-container {
          flex: 1;
          width: 100%;
        }

        margin-bottom: 20px;
      }
    }
  }
  html:not([data-scroll='111110']) {
    .floating-button {
      display: block;
    }
  }
}

@media print {
  /* invert the display (show/hide) properties of the product */
  /* application component and the template component       */
  .topbar {

    display: none !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ styles here
  app-header-and-footer {
    display: block !important;
  }
}
