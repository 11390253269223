.flag {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("sprite.svg");
}
.flag_ad {
  width: 43px;
  height: 32px;
  background-position: 0 0;
}
.flag_ae {
  width: 43px;
  height: 32px;
  background-position: -96px 0;
}
.flag_af {
  width: 43px;
  height: 32px;
  background-position: -192px 0;
}
.flag_ag {
  width: 43px;
  height: 32px;
  background-position: -288px 0;
}
.flag_ai {
  width: 43px;
  height: 32px;
  background-position: -384px 0;
}
.flag_al {
  width: 43px;
  height: 32px;
  background-position: -480px 0;
}
.flag_am {
  width: 43px;
  height: 32px;
  background-position: -576px 0;
}
.flag_ao {
  width: 43px;
  height: 32px;
  background-position: -672px 0;
}
.flag_aq {
  width: 43px;
  height: 32px;
  background-position: 0 -48px;
}
.flag_ar {
  width: 43px;
  height: 32px;
  background-position: -96px -48px;
}
.flag_as {
  width: 43px;
  height: 32px;
  background-position: -192px -48px;
}
.flag_at {
  width: 43px;
  height: 32px;
  background-position: -288px -48px;
}
.flag_au {
  width: 43px;
  height: 32px;
  background-position: -384px -48px;
}
.flag_aw {
  width: 43px;
  height: 32px;
  background-position: -480px -48px;
}
.flag_ax {
  width: 43px;
  height: 32px;
  background-position: -576px -48px;
}
.flag_az {
  width: 43px;
  height: 32px;
  background-position: -672px -48px;
}
.flag_ba {
  width: 43px;
  height: 32px;
  background-position: 0 -96px;
}
.flag_bb {
  width: 43px;
  height: 32px;
  background-position: -96px -96px;
}
.flag_bd {
  width: 43px;
  height: 32px;
  background-position: -192px -96px;
}
.flag_be {
  width: 43px;
  height: 32px;
  background-position: -288px -96px;
}
.flag_bf {
  width: 43px;
  height: 32px;
  background-position: -384px -96px;
}
.flag_bg {
  width: 43px;
  height: 32px;
  background-position: -480px -96px;
}
.flag_bh {
  width: 43px;
  height: 32px;
  background-position: -576px -96px;
}
.flag_bi {
  width: 43px;
  height: 32px;
  background-position: -672px -96px;
}
.flag_bj {
  width: 43px;
  height: 32px;
  background-position: 0 -144px;
}
.flag_bl {
  width: 43px;
  height: 32px;
  background-position: -96px -144px;
}
.flag_bm {
  width: 43px;
  height: 32px;
  background-position: -192px -144px;
}
.flag_bn {
  width: 43px;
  height: 32px;
  background-position: -288px -144px;
}
.flag_bo {
  width: 43px;
  height: 32px;
  background-position: -384px -144px;
}
.flag_bq {
  width: 43px;
  height: 32px;
  background-position: -480px -144px;
}
.flag_br {
  width: 43px;
  height: 32px;
  background-position: -576px -144px;
}
.flag_bs {
  width: 43px;
  height: 32px;
  background-position: -672px -144px;
}
.flag_bt {
  width: 43px;
  height: 32px;
  background-position: 0 -192px;
}
.flag_bv {
  width: 43px;
  height: 32px;
  background-position: -96px -192px;
}
.flag_bw {
  width: 43px;
  height: 32px;
  background-position: -192px -192px;
}
.flag_by {
  width: 43px;
  height: 32px;
  background-position: -288px -192px;
}
.flag_bz {
  width: 43px;
  height: 32px;
  background-position: -384px -192px;
}
.flag_ca {
  width: 43px;
  height: 32px;
  background-position: -480px -192px;
}
.flag_cc {
  width: 43px;
  height: 32px;
  background-position: -576px -192px;
}
.flag_cd {
  width: 43px;
  height: 32px;
  background-position: -672px -192px;
}
.flag_cf {
  width: 43px;
  height: 32px;
  background-position: 0 -240px;
}
.flag_cg {
  width: 43px;
  height: 32px;
  background-position: -96px -240px;
}
.flag_ch {
  width: 43px;
  height: 32px;
  background-position: -192px -240px;
}
.flag_ci {
  width: 43px;
  height: 32px;
  background-position: -288px -240px;
}
.flag_ck {
  width: 43px;
  height: 32px;
  background-position: -384px -240px;
}
.flag_cl {
  width: 43px;
  height: 32px;
  background-position: -480px -240px;
}
.flag_cm {
  width: 43px;
  height: 32px;
  background-position: -576px -240px;
}
.flag_cn {
  width: 43px;
  height: 32px;
  background-position: -672px -240px;
}
.flag_co {
  width: 43px;
  height: 32px;
  background-position: 0 -288px;
}
.flag_cr {
  width: 43px;
  height: 32px;
  background-position: -96px -288px;
}
.flag_cu {
  width: 43px;
  height: 32px;
  background-position: -192px -288px;
}
.flag_cv {
  width: 43px;
  height: 32px;
  background-position: -288px -288px;
}
.flag_cw {
  width: 43px;
  height: 32px;
  background-position: -384px -288px;
}
.flag_cx {
  width: 43px;
  height: 32px;
  background-position: -480px -288px;
}
.flag_cy {
  width: 43px;
  height: 32px;
  background-position: -576px -288px;
}
.flag_cz {
  width: 43px;
  height: 32px;
  background-position: -672px -288px;
}
.flag_de {
  width: 43px;
  height: 32px;
  background-position: 0 -336px;
}
.flag_dj {
  width: 43px;
  height: 32px;
  background-position: -96px -336px;
}
.flag_dk {
  width: 43px;
  height: 32px;
  background-position: -192px -336px;
}
.flag_dm {
  width: 43px;
  height: 32px;
  background-position: -288px -336px;
}
.flag_do {
  width: 43px;
  height: 32px;
  background-position: -384px -336px;
}
.flag_dz {
  width: 43px;
  height: 32px;
  background-position: -480px -336px;
}
.flag_ec {
  width: 43px;
  height: 32px;
  background-position: -576px -336px;
}
.flag_ee {
  width: 43px;
  height: 32px;
  background-position: -672px -336px;
}
.flag_eg {
  width: 43px;
  height: 32px;
  background-position: 0 -384px;
}
.flag_eh {
  width: 43px;
  height: 32px;
  background-position: -96px -384px;
}
.flag_er {
  width: 43px;
  height: 32px;
  background-position: -192px -384px;
}
.flag_es {
  width: 43px;
  height: 32px;
  background-position: -288px -384px;
}
.flag_es-ca {
  width: 43px;
  height: 32px;
  background-position: -384px -384px;
}
.flag_et {
  width: 43px;
  height: 32px;
  background-position: -480px -384px;
}
.flag_eu {
  width: 43px;
  height: 32px;
  background-position: -576px -384px;
}
.flag_fi {
  width: 43px;
  height: 32px;
  background-position: -672px -384px;
}
.flag_fj {
  width: 43px;
  height: 32px;
  background-position: 0 -432px;
}
.flag_fk {
  width: 43px;
  height: 32px;
  background-position: -96px -432px;
}
.flag_fm {
  width: 43px;
  height: 32px;
  background-position: -192px -432px;
}
.flag_fo {
  width: 43px;
  height: 32px;
  background-position: -288px -432px;
}
.flag_fr {
  width: 43px;
  height: 32px;
  background-position: -384px -432px;
}
.flag_ga {
  width: 43px;
  height: 32px;
  background-position: -480px -432px;
}
.flag_gb {
  width: 43px;
  height: 32px;
  background-position: -576px -432px;
}
.flag_gb-eng {
  width: 43px;
  height: 32px;
  background-position: -672px -432px;
}
.flag_gb-nir {
  width: 43px;
  height: 32px;
  background-position: 0 -480px;
}
.flag_gb-sct {
  width: 43px;
  height: 32px;
  background-position: -96px -480px;
}
.flag_gb-wls {
  width: 43px;
  height: 32px;
  background-position: -192px -480px;
}
.flag_gd {
  width: 43px;
  height: 32px;
  background-position: -288px -480px;
}
.flag_ge {
  width: 43px;
  height: 32px;
  background-position: -384px -480px;
}
.flag_gf {
  width: 43px;
  height: 32px;
  background-position: -480px -480px;
}
.flag_gg {
  width: 43px;
  height: 32px;
  background-position: -576px -480px;
}
.flag_gh {
  width: 43px;
  height: 32px;
  background-position: -672px -480px;
}
.flag_gi {
  width: 43px;
  height: 32px;
  background-position: 0 -528px;
}
.flag_gl {
  width: 43px;
  height: 32px;
  background-position: -96px -528px;
}
.flag_gm {
  width: 43px;
  height: 32px;
  background-position: -192px -528px;
}
.flag_gn {
  width: 43px;
  height: 32px;
  background-position: -288px -528px;
}
.flag_gp {
  width: 43px;
  height: 32px;
  background-position: -384px -528px;
}
.flag_gq {
  width: 43px;
  height: 32px;
  background-position: -480px -528px;
}
.flag_gr {
  width: 43px;
  height: 32px;
  background-position: -576px -528px;
}
.flag_gs {
  width: 43px;
  height: 32px;
  background-position: -672px -528px;
}
.flag_gt {
  width: 43px;
  height: 32px;
  background-position: 0 -576px;
}
.flag_gu {
  width: 43px;
  height: 32px;
  background-position: -96px -576px;
}
.flag_gw {
  width: 43px;
  height: 32px;
  background-position: -192px -576px;
}
.flag_gy {
  width: 43px;
  height: 32px;
  background-position: -288px -576px;
}
.flag_hk {
  width: 43px;
  height: 32px;
  background-position: -384px -576px;
}
.flag_hm {
  width: 43px;
  height: 32px;
  background-position: -480px -576px;
}
.flag_hn {
  width: 43px;
  height: 32px;
  background-position: -576px -576px;
}
.flag_hr {
  width: 43px;
  height: 32px;
  background-position: -672px -576px;
}
.flag_ht {
  width: 43px;
  height: 32px;
  background-position: 0 -624px;
}
.flag_hu {
  width: 43px;
  height: 32px;
  background-position: -96px -624px;
}
.flag_id {
  width: 43px;
  height: 32px;
  background-position: -192px -624px;
}
.flag_ie {
  width: 43px;
  height: 32px;
  background-position: -288px -624px;
}
.flag_il {
  width: 43px;
  height: 32px;
  background-position: -384px -624px;
}
.flag_im {
  width: 43px;
  height: 32px;
  background-position: -480px -624px;
}
.flag_in {
  width: 43px;
  height: 32px;
  background-position: -576px -624px;
}
.flag_io {
  width: 43px;
  height: 32px;
  background-position: -672px -624px;
}
.flag_iq {
  width: 43px;
  height: 32px;
  background-position: 0 -672px;
}
.flag_ir {
  width: 43px;
  height: 32px;
  background-position: -96px -672px;
}
.flag_is {
  width: 43px;
  height: 32px;
  background-position: -192px -672px;
}
.flag_it {
  width: 43px;
  height: 32px;
  background-position: -288px -672px;
}
.flag_je {
  width: 53px;
  height: 32px;
  background-position: -384px -672px;
}
.flag_jm {
  width: 43px;
  height: 32px;
  background-position: -480px -672px;
}
.flag_jo {
  width: 43px;
  height: 32px;
  background-position: -576px -672px;
}
.flag_jp {
  width: 43px;
  height: 32px;
  background-position: -672px -672px;
}
.flag_ke {
  width: 43px;
  height: 32px;
  background-position: 0 -720px;
}
.flag_kg {
  width: 43px;
  height: 32px;
  background-position: -96px -720px;
}
.flag_kh {
  width: 43px;
  height: 32px;
  background-position: -192px -720px;
}
.flag_ki {
  width: 43px;
  height: 32px;
  background-position: -288px -720px;
}
.flag_km {
  width: 43px;
  height: 32px;
  background-position: -384px -720px;
}
.flag_kn {
  width: 43px;
  height: 32px;
  background-position: -480px -720px;
}
.flag_kp {
  width: 43px;
  height: 32px;
  background-position: -576px -720px;
}
.flag_kr {
  width: 43px;
  height: 32px;
  background-position: -672px -720px;
}
.flag_kw {
  width: 43px;
  height: 32px;
  background-position: 0 -768px;
}
.flag_ky {
  width: 43px;
  height: 32px;
  background-position: -96px -768px;
}
.flag_kz {
  width: 43px;
  height: 32px;
  background-position: -192px -768px;
}
.flag_la {
  width: 43px;
  height: 32px;
  background-position: -288px -768px;
}
.flag_lb {
  width: 43px;
  height: 32px;
  background-position: -384px -768px;
}
.flag_lc {
  width: 43px;
  height: 32px;
  background-position: -480px -768px;
}
.flag_li {
  width: 43px;
  height: 32px;
  background-position: -576px -768px;
}
.flag_lk {
  width: 43px;
  height: 32px;
  background-position: -672px -768px;
}
.flag_lr {
  width: 43px;
  height: 32px;
  background-position: 0 -816px;
}
.flag_ls {
  width: 43px;
  height: 32px;
  background-position: -96px -816px;
}
.flag_lt {
  width: 43px;
  height: 32px;
  background-position: -192px -816px;
}
.flag_lu {
  width: 43px;
  height: 32px;
  background-position: -288px -816px;
}
.flag_lv {
  width: 43px;
  height: 32px;
  background-position: -384px -816px;
}
.flag_ly {
  width: 43px;
  height: 32px;
  background-position: -480px -816px;
}
.flag_ma {
  width: 43px;
  height: 32px;
  background-position: -576px -816px;
}
.flag_mc {
  width: 43px;
  height: 32px;
  background-position: -672px -816px;
}
.flag_md {
  width: 43px;
  height: 32px;
  background-position: 0 -864px;
}
.flag_me {
  width: 43px;
  height: 32px;
  background-position: -96px -864px;
}
.flag_mf {
  width: 43px;
  height: 32px;
  background-position: -192px -864px;
}
.flag_mg {
  width: 43px;
  height: 32px;
  background-position: -288px -864px;
}
.flag_mh {
  width: 43px;
  height: 32px;
  background-position: -384px -864px;
}
.flag_mk {
  width: 43px;
  height: 32px;
  background-position: -480px -864px;
}
.flag_ml {
  width: 43px;
  height: 32px;
  background-position: -576px -864px;
}
.flag_mm {
  width: 43px;
  height: 32px;
  background-position: -672px -864px;
}
.flag_mn {
  width: 43px;
  height: 32px;
  background-position: 0 -912px;
}
.flag_mo {
  width: 43px;
  height: 32px;
  background-position: -96px -912px;
}
.flag_mp {
  width: 43px;
  height: 32px;
  background-position: -192px -912px;
}
.flag_mq {
  width: 43px;
  height: 32px;
  background-position: -288px -912px;
}
.flag_mr {
  width: 43px;
  height: 32px;
  background-position: -384px -912px;
}
.flag_ms {
  width: 43px;
  height: 32px;
  background-position: -480px -912px;
}
.flag_mt {
  width: 43px;
  height: 32px;
  background-position: -576px -912px;
}
.flag_mu {
  width: 43px;
  height: 32px;
  background-position: -672px -912px;
}
.flag_mv {
  width: 43px;
  height: 32px;
  background-position: 0 -960px;
}
.flag_mw {
  width: 43px;
  height: 32px;
  background-position: -96px -960px;
}
.flag_mx {
  width: 43px;
  height: 32px;
  background-position: -192px -960px;
}
.flag_my {
  width: 43px;
  height: 32px;
  background-position: -288px -960px;
}
.flag_mz {
  width: 43px;
  height: 32px;
  background-position: -384px -960px;
}
.flag_na {
  width: 43px;
  height: 32px;
  background-position: -480px -960px;
}
.flag_nc {
  width: 43px;
  height: 32px;
  background-position: -576px -960px;
}
.flag_ne {
  width: 43px;
  height: 32px;
  background-position: -672px -960px;
}
.flag_nf {
  width: 43px;
  height: 32px;
  background-position: 0 -1008px;
}
.flag_ng {
  width: 43px;
  height: 32px;
  background-position: -96px -1008px;
}
.flag_ni {
  width: 43px;
  height: 32px;
  background-position: -192px -1008px;
}
.flag_nl {
  width: 43px;
  height: 32px;
  background-position: -288px -1008px;
}
.flag_no {
  width: 43px;
  height: 32px;
  background-position: -384px -1008px;
}
.flag_np {
  width: 43px;
  height: 32px;
  background-position: -480px -1008px;
}
.flag_nr {
  width: 43px;
  height: 32px;
  background-position: -576px -1008px;
}
.flag_nu {
  width: 43px;
  height: 32px;
  background-position: -672px -1008px;
}
.flag_nz {
  width: 43px;
  height: 32px;
  background-position: 0 -1056px;
}
.flag_om {
  width: 43px;
  height: 32px;
  background-position: -96px -1056px;
}
.flag_pa {
  width: 43px;
  height: 32px;
  background-position: -192px -1056px;
}
.flag_pe {
  width: 43px;
  height: 32px;
  background-position: -288px -1056px;
}
.flag_pf {
  width: 43px;
  height: 32px;
  background-position: -384px -1056px;
}
.flag_pg {
  width: 43px;
  height: 32px;
  background-position: -480px -1056px;
}
.flag_ph {
  width: 43px;
  height: 32px;
  background-position: -576px -1056px;
}
.flag_pk {
  width: 43px;
  height: 32px;
  background-position: -672px -1056px;
}
.flag_pl {
  width: 43px;
  height: 32px;
  background-position: 0 -1104px;
}
.flag_pm {
  width: 43px;
  height: 32px;
  background-position: -96px -1104px;
}
.flag_pn {
  width: 43px;
  height: 32px;
  background-position: -192px -1104px;
}
.flag_pr {
  width: 43px;
  height: 32px;
  background-position: -288px -1104px;
}
.flag_ps {
  width: 43px;
  height: 32px;
  background-position: -384px -1104px;
}
.flag_pt {
  width: 43px;
  height: 32px;
  background-position: -480px -1104px;
}
.flag_pw {
  width: 43px;
  height: 32px;
  background-position: -576px -1104px;
}
.flag_py {
  width: 43px;
  height: 32px;
  background-position: -672px -1104px;
}
.flag_qa {
  width: 43px;
  height: 32px;
  background-position: 0 -1152px;
}
.flag_re {
  width: 43px;
  height: 32px;
  background-position: -96px -1152px;
}
.flag_ro {
  width: 43px;
  height: 32px;
  background-position: -192px -1152px;
}
.flag_rs {
  width: 43px;
  height: 32px;
  background-position: -288px -1152px;
}
.flag_ru {
  width: 43px;
  height: 32px;
  background-position: -384px -1152px;
}
.flag_rw {
  width: 43px;
  height: 32px;
  background-position: -480px -1152px;
}
.flag_sa {
  width: 43px;
  height: 32px;
  background-position: -576px -1152px;
}
.flag_sb {
  width: 43px;
  height: 32px;
  background-position: -672px -1152px;
}
.flag_sc {
  width: 43px;
  height: 32px;
  background-position: 0 -1200px;
}
.flag_sd {
  width: 43px;
  height: 32px;
  background-position: -96px -1200px;
}
.flag_se {
  width: 43px;
  height: 32px;
  background-position: -192px -1200px;
}
.flag_sg {
  width: 43px;
  height: 32px;
  background-position: -288px -1200px;
}
.flag_sh {
  width: 43px;
  height: 32px;
  background-position: -384px -1200px;
}
.flag_si {
  width: 43px;
  height: 32px;
  background-position: -480px -1200px;
}
.flag_sj {
  width: 43px;
  height: 32px;
  background-position: -576px -1200px;
}
.flag_sk {
  width: 43px;
  height: 32px;
  background-position: -672px -1200px;
}
.flag_sl {
  width: 43px;
  height: 32px;
  background-position: 0 -1248px;
}
.flag_sm {
  width: 43px;
  height: 32px;
  background-position: -96px -1248px;
}
.flag_sn {
  width: 43px;
  height: 32px;
  background-position: -192px -1248px;
}
.flag_so {
  width: 43px;
  height: 32px;
  background-position: -288px -1248px;
}
.flag_sr {
  width: 43px;
  height: 32px;
  background-position: -384px -1248px;
}
.flag_ss {
  width: 43px;
  height: 32px;
  background-position: -480px -1248px;
}
.flag_st {
  width: 43px;
  height: 32px;
  background-position: -576px -1248px;
}
.flag_sv {
  width: 43px;
  height: 32px;
  background-position: -672px -1248px;
}
.flag_sx {
  width: 43px;
  height: 32px;
  background-position: 0 -1296px;
}
.flag_sy {
  width: 43px;
  height: 32px;
  background-position: -96px -1296px;
}
.flag_sz {
  width: 43px;
  height: 32px;
  background-position: -192px -1296px;
}
.flag_tc {
  width: 43px;
  height: 32px;
  background-position: -288px -1296px;
}
.flag_td {
  width: 43px;
  height: 32px;
  background-position: -384px -1296px;
}
.flag_tf {
  width: 43px;
  height: 32px;
  background-position: -480px -1296px;
}
.flag_tg {
  width: 43px;
  height: 32px;
  background-position: -576px -1296px;
}
.flag_th {
  width: 43px;
  height: 32px;
  background-position: -672px -1296px;
}
.flag_tj {
  width: 43px;
  height: 32px;
  background-position: 0 -1344px;
}
.flag_tk {
  width: 43px;
  height: 32px;
  background-position: -96px -1344px;
}
.flag_tl {
  width: 43px;
  height: 32px;
  background-position: -192px -1344px;
}
.flag_tm {
  width: 43px;
  height: 32px;
  background-position: -288px -1344px;
}
.flag_tn {
  width: 43px;
  height: 32px;
  background-position: -384px -1344px;
}
.flag_to {
  width: 43px;
  height: 32px;
  background-position: -480px -1344px;
}
.flag_tr {
  width: 43px;
  height: 32px;
  background-position: -576px -1344px;
}
.flag_tt {
  width: 43px;
  height: 32px;
  background-position: -672px -1344px;
}
.flag_tv {
  width: 43px;
  height: 32px;
  background-position: 0 -1392px;
}
.flag_tw {
  width: 43px;
  height: 32px;
  background-position: -96px -1392px;
}
.flag_tz {
  width: 43px;
  height: 32px;
  background-position: -192px -1392px;
}
.flag_ua {
  width: 43px;
  height: 32px;
  background-position: -288px -1392px;
}
.flag_ug {
  width: 43px;
  height: 32px;
  background-position: -384px -1392px;
}
.flag_um {
  width: 43px;
  height: 32px;
  background-position: -480px -1392px;
}
.flag_un {
  width: 43px;
  height: 32px;
  background-position: -576px -1392px;
}
.flag_us {
  width: 43px;
  height: 32px;
  background-position: -672px -1392px;
}
.flag_uy {
  width: 43px;
  height: 32px;
  background-position: 0 -1440px;
}
.flag_uz {
  width: 43px;
  height: 32px;
  background-position: -96px -1440px;
}
.flag_va {
  width: 43px;
  height: 32px;
  background-position: -192px -1440px;
}
.flag_vc {
  width: 43px;
  height: 32px;
  background-position: -288px -1440px;
}
.flag_ve {
  width: 43px;
  height: 32px;
  background-position: -384px -1440px;
}
.flag_vg {
  width: 43px;
  height: 32px;
  background-position: -480px -1440px;
}
.flag_vi {
  width: 43px;
  height: 32px;
  background-position: -576px -1440px;
}
.flag_vn {
  width: 43px;
  height: 32px;
  background-position: -672px -1440px;
}
.flag_vu {
  width: 43px;
  height: 32px;
  background-position: 0 -1488px;
}
.flag_wf {
  width: 43px;
  height: 32px;
  background-position: -96px -1488px;
}
.flag_ws {
  width: 43px;
  height: 32px;
  background-position: -192px -1488px;
}
.flag_xk {
  width: 43px;
  height: 32px;
  background-position: -288px -1488px;
}
.flag_ye {
  width: 43px;
  height: 32px;
  background-position: -384px -1488px;
}
.flag_yt {
  width: 43px;
  height: 32px;
  background-position: -480px -1488px;
}
.flag_za {
  width: 43px;
  height: 32px;
  background-position: -576px -1488px;
}
.flag_zm {
  width: 43px;
  height: 32px;
  background-position: -672px -1488px;
}
.flag_zw {
  width: 43px;
  height: 32px;
  background-position: 0 -1536px;
}

