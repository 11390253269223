@mixin content-menu-theme($theme) {
  app-content-menu {
    .nav {
      width: 100%;
      user-select: none;
      height: 36px;
      background: map-get($theme, "primary");

      .nav-item-container {
        height: 100%;
        display: flex;
        background: map-get($theme, "primary");
        .nav-item {
          padding: 0 4.2em 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          color: white;
          font-weight: bold;
          opacity: 0.75;
          text-transform: uppercase;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          &.activeItem{
            opacity: 1;
          }
        }
      }

    }
    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {
    display: none;
    }
  }
}
