@use 'variables';

@import "../projects/ngx-sitemule/dist/ngx-sitemule/theme.scss";
@import "./app/components/footer/footer.component.scss";
@import "./app/components/header/header.component.scss";
@import "./app/components/header-and-footer/header-and-footer.component.scss";
@import "./app/components/content-menu/content-menu.component.scss";
@import "./app/components/content-menu-submenus/context-menu-product/context-menu-product.component.scss";
@import "./app/components/content-menu-submenus/context-menu-large/context-menu-large.component.scss";
@import "./app/components/content-menu-submenus/context-menu-flesupport/context-menu-flesupport.component.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");
@import "./styleOverrides/icons.scss";
@import "./styleOverrides/pages.scss";

@include theme-webshop(variables.$theme);
@include theme-ui(variables.$theme);
@include theme-page(variables.$theme);
@include theme-user(variables.$theme);
@include footer-theme(variables.$theme);
@include header-theme(variables.$theme);
@include header-footer-theme(variables.$theme);
@include content-menu-theme(variables.$theme);
@include context-menu-product-theme(variables.$theme);
@include context-menu-large-theme(variables.$theme);
@include context-menu-flesupport-theme(variables.$theme);
@include pagesOverride(variables.$theme);
html,
body {
  font-family: "Raleway", sans-serif;
  margin: 0;
  font-size: 12px;
  margin: auto;
}

h1 {
  color: #353535;
  font-size: 35px;
  line-height: 50px;
  font-weight: 100;
  margin: 0;
  margin-bottom: 30px;
  word-break: keep-all;
}
h2 {
  color: #353535;
  font-size: 35px;
  line-height: 40px;
  font-weight: 100;
  margin: 0;
  margin-bottom: 30px;
}
h3 {
  color: #353535;
  font-size: 25px;
  line-height: 35px;
  font-weight: 100;
  margin: 0;
  margin-bottom: 30px;
}
p {
  font-weight: inherit;
}
a,
.alink {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
table:not(.p-datatable-table) {
  border: none;
  min-width: 100%;
  border-collapse: collapse;
  tbody {
    @extend .ui-table !optional;
    margin-top: 0;
    margin-bottom: 30px;
    width: 100%;
    td {
      min-width: 65px;
      word-break: break-word;
    }
  }
}

input:focus,
textarea:focus,
select:focus,
* {
}
:root {
  --animate-duration: 0.5s !important;
}

.ui-accordion .active,
ui-accordion .accordion:hover:not(:disabled) {
  color: inherit !important;
}
.sticky-input {
  font-size: 14px;
  z-index: 1;
  &::placeholder {
    color: #aaaaaa;
  }
  position: sticky;
  top: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.22);
}
.p-component {
  font-family: "Raleway", sans-serif !important;
}

//UI-menu
user-menu ui-menu .ui-menu-container .ui-menu-container {
  border-left: 1px solid var(--black-color-800);
  .menu-item {
    background: transparent;
  }
}
user-menu ui-menu .ui-menu-container .menu-item {
  background: transparent;
  color: #737373;

  &:hover,
  &.activeItem {
    color: var(--primary-color-800);
  }
  .label-container {
    white-space: break-spaces;
  }
}
ui-menu .ui-menu-container ui-menu .ui-menu-container .menu-item {
  background: transparent;
}
//ui-menu end

//ng prime select highlight
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #212529;
  color: #ffffff;
}

.p-dropdown:not(.p-disabled).p-focus{
  border-color: #212529;
  box-shadow: none;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff !important;
  color: #212529;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #ffffff !important;
  color: #212529;
}
