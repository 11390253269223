@mixin context-menu-large-theme($theme) {
  app-context-menu-large {
    h1 {
      margin: 15px;
    }

    .catalog-menu {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      padding: 20px;

      .catalog-item {
        flex: 1;
        display: flex;
        padding: 50px;
        background: white;
        align-items: center;
        color: map-get($theme, 'primary');
        justify-content: center;
        height: 250px;
        font-size: 25px;
        flex-direction: column;
        text-align: center;

        margin: 5px;

        i {
          font-size: 55px;
          color: grey;
          margin-bottom: 10px;
        }

        &:hover {
          background: map-get(map-get($theme, 'element'), 'dark');
        }

        .catalog-item-txt {
          font-size: 16px;
          color: map-get(map-get($theme, "text"), "black");
          line-height: 25px;
        }
      }
    }

    @media (max-width: map-get(map-get($theme, "breakpoints"), "phone")) {

    }
  }
}
