@mixin context-menu-product-theme($theme) {
  app-context-menu-product {
    h1 {
      margin: 15px;
    }

    .catalog-menu {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      .catalog-item {
        width: 20%;
        height: 50px;
        display: flex;
        font-size: 14px;
        padding-left: 10px;
        align-items: center;
        color: map-get(map-get($theme, 'text'), 'dark');
        background: map-get(map-get($theme, 'element'), 'light');
        border-bottom: 1px solid map-get(map-get($theme, 'element'), 'base');
        border-right: 1px solid map-get(map-get($theme, 'element'), 'base');

        &:nth-child(-n+5) {
          border-top: 1px solid map-get(map-get($theme, 'element'), 'base');
        }

        &:nth-last-child(-n+2) {
          border-bottom: none;
        }

        &:hover {
          background: map-get(map-get($theme, 'element'), 'dark');
        }
      }
    }
  }
}
