@mixin context-menu-flesupport-theme($theme) {
  app-context-menu-flesupport {
    h1 {
      margin: 15px;
    }

    .catalog-menu {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      padding: 20px;

      .catalog-item {
        flex: 1;
        display: flex;
        padding: 10px;
        color: #003b6e;
        height: 250px;
        font-size: 25px;
        flex-direction: column;
        border-right: 1px solid black;
        cursor: default;
        justify-content: space-between;
        margin: 5px;
        div {
          display: flex;
          flex-direction: column;

          i {
            font-size: 30px;
            color: grey;
            margin-bottom: 10px;
          }
        }

        &:last-child {
          border: none;
        }



        &:hover {
          background: map-get(map-get($theme, 'element'), 'dark');
        }

        .catalog-item-txt {
          font-size: 14px;
          color: map-get(map-get($theme, "text"), "black");
          white-space: pre;
        }

        .catalog-item-link {
          font-size: 14px;
          color: darkblue;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
